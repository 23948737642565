import { ProposalPage, PROPOSAL_TYPES } from '../components/Store/Types/models/Proposal'

const proposalTitle = (
  proposal: Pick<
    ProposalPage,
    'type' | 'user' | 'address' | 'amountOfFunds' | 'tokenSymbol' | 'nonce'
  >,
  proposalPage?: boolean
  // eslint-disable-next-line consistent-return
) => {
  if (proposalPage)
    // eslint-disable-next-line default-case
    switch (proposal.type) {
      case PROPOSAL_TYPES.ADD_PARTICIPANT:
        return 'Add participant to'
      case PROPOSAL_TYPES.REMOVE_PARTICIPANT:
        return 'Remove participant from'
      case PROPOSAL_TYPES.TRANSFER_FUNDS:
        return `Send ${proposal.amountOfFunds} ETH to`
      case PROPOSAL_TYPES.CONTRACT_INTERACTION:
        return `Send ${proposal.amountOfFunds} ${proposal.tokenSymbol} to`
      case PROPOSAL_TYPES.REJECT_PROPOSAL:
        return `Reject proposal № ${proposal.nonce} in`
    }
  // eslint-disable-next-line default-case
  switch (proposal.type) {
    case PROPOSAL_TYPES.ADD_PARTICIPANT:
      return `Add ${proposal.user?.username || proposal.address}`
    case PROPOSAL_TYPES.REMOVE_PARTICIPANT:
      return `Remove ${proposal.user?.username || proposal.address}`
    case PROPOSAL_TYPES.TRANSFER_FUNDS:
      return `Send ${proposal.amountOfFunds} ETH`
    case PROPOSAL_TYPES.CONTRACT_INTERACTION:
      return `Send ${proposal.amountOfFunds} ${proposal.tokenSymbol}`
    case PROPOSAL_TYPES.REJECT_PROPOSAL:
      return `Reject proposal № ${proposal.nonce}`
  }
}

export default proposalTitle
