import { Toaster } from 'react-hot-toast'

import Loader from './components/Loader'
import ScrollToTop from './components/ScrollToTop'
import Header from './components/Header'
import Popup from './components/Popup'
import SwipeHandler from './components/SwipeHandler'

import './styles/index.sass'
import ProtectedRoutes from './components/ProtectedRoutes'

const App: React.FC = () => {
  return (
    <>
      <Loader>
        <SwipeHandler>
          <div className="App">
            <ScrollToTop>
              <Header />
              <div className="content">
                <ProtectedRoutes />
                <Popup />
              </div>
            </ScrollToTop>
          </div>
        </SwipeHandler>
      </Loader>
      <Toaster />
    </>
  )
}

export default App
