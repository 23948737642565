import React from 'react'

import { useNavigate } from 'react-router-dom'

import Link from '../../components/Link'
import Title from '../../components/Title'
import Button from '../../components/Button'
import CopyField from '../../components/CopyField'
import MembersList from '../../components/MembersList'
import { Group } from '../../components/Store/Types/models/Group'
import DaoAssets from '../../components/DaoAssets'


type PendingProps = {
  group: Group
}


const Pending: React.FC<PendingProps> = ({ group }) => {
  const navigate = useNavigate()

  return (
    <div className='container d-flex flex-column'>
      <Title title={group.title} users={group.users.length} className="mb-15" />
      <div className="align-items-center mb-37">
        <div className="mb-10">
          {group.gnosisUrl && (
            <div className={'mb-5'}>
              <Link to={group.gnosisUrl} className={'underline link'}>
                "Gnosis Safe" link
              </Link>
            </div>
          )}
          <CopyField label="DAO address">{group.DAOaddress}</CopyField>
        </div>
        <DaoAssets groupId={group.id} daoAssets={group?.DAObalance || []} />
      </div>
      <MembersList
        group={group}
        editable
        addButton
        dropdown
        className="mb-auto"
        onClick={(user) => {
          navigate(
            `/${group.id}/new-proposal/remove-participant?address=${user.address}`
          )
          window?.Telegram?.WebApp?.BackButton?.show?.()
        }}
      />
      <Link to={`/proposals/${group.id}/1`} className="d-block mb-10">
        <Button
          disabled={!group?.numberOfProposals || group?.numberOfProposals === 0}
          left="Proposals"
          right={group.numberOfProposals || 0}
          green
          className="px-24"
        />
      </Link>
      <Link to={`/${group.id}/new-proposal`}>
        <Button pink className="px-24">
          New Proposal
        </Button>
      </Link>
    </div>
  )
}


export default Pending
