import React from 'react'
import { useSwipeable } from 'react-swipeable'
import { useNavigate } from 'react-router-dom'


export type SwipeHandlerProps = {
  children: React.ReactNode
}


const SwipeHandler: React.FC<SwipeHandlerProps> = ({ children }) => {
  const navigate = useNavigate()
  const swipeHandlers = useSwipeable({
    // onSwipedDown: e => {
    //   navigate('/refresh')
    //   setTimeout(() => navigate(-1), 50)
    // },
    // onSwipedLeft: e => navigate(-1),
    // onSwipedRight: e => navigate(1),
  })

  return (
    <div {...swipeHandlers} className='h-100'>
      {children}
    </div>
  )
}


export default SwipeHandler