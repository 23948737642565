import { Asset } from '../components/Store/Types/models/Asset'

const isAssetsEqual = (a: Asset, b: Asset): boolean => {
  return (
    a.tokenSymbol.toLocaleLowerCase() === b.tokenSymbol.toLocaleLowerCase() &&
    a.balance === b.balance
  )
}

export default isAssetsEqual
