import React from 'react'

import toast from 'react-hot-toast'

import { ProposalListItem, PROPOSAL_TYPES } from './Store/Types/models/Proposal'
import Button from './Button'
import Link from './Link'
import proposalTitle from '../utils/proposalTitle'

type ProposalItemProps = {
  proposal: ProposalListItem
  threshold: number
  loading: boolean
  vote: (id: number) => Promise<any>
  execute: (id: number) => Promise<any>
  reject: (proposalId: number) => Promise<any>
  refetch: () => Promise<any>
  className?: string
}

const ProposalItem: React.FC<ProposalItemProps> = ({
  proposal,
  threshold,
  loading,
  vote,
  execute,
  refetch,
  reject,
  className
}) => {
  async function handleConfirm(id: any): Promise<any> {
    try {
      const result = await vote(id)

      if (result && result.status === 201) {
        toast.success('Successfully executed!')
        await refetch()
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message)
    }
  }

  async function handleExecute(id: any): Promise<any> {
    try {
      const result = await execute(id)

      if (result && result.status === 201) {
        toast.success('Successfully executed!')
        await refetch()
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message)
    }
  }

  async function handleReject(proposalId: number) {
    try {
      const result = await reject(proposalId)

      if (result && result.status === 201) {
        toast.success('Successfully create proposal to reject!')
        await refetch()
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message)
    }
  }

  return (
    <div className={`ProposalItem ${className}`}>
      <Link to={`/proposals/${proposal.id}`}>
        <div className="container">
          <div className="d-flex flex-row justify-content-between">
            <div className="ProposalItem__title">
              {proposal.type === PROPOSAL_TYPES.REJECT_PROPOSAL
                ? `${proposalTitle(proposal)}`
                : `${proposal.nonce}. ${proposalTitle(proposal)}`}
            </div>
            <div className="ProposalItem__confirmations">
              {proposal.confirmations}/{threshold}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-start">
            {!proposal.confirmedByUser && (
              <Button
                green
                disabled={loading}
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault()
                  event.stopPropagation()
                  return handleConfirm(proposal.id)
                }}
              >
                Confirm
              </Button>
            )}
            {proposal.canBeExecuted && (
              <Button
                green
                disabled={loading}
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault()
                  event.stopPropagation()
                  return handleExecute(proposal.id)
                }}
              >
                Execute
              </Button>
            )}
            {proposal.canBeRejected && (
              <Button
                pink
                disabled={loading}
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault()
                  event.stopPropagation()
                  return handleReject(proposal.id)
                }}
              >
                Reject
              </Button>
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ProposalItem
