import { User } from './User'

export enum PROPOSAL_TYPES {
  ADD_PARTICIPANT = 'add participant',
  REMOVE_PARTICIPANT = 'remove participant',
  TRANSFER_FUNDS = 'transfer ethers',
  CONTRACT_INTERACTION = 'contract interaction',
  CUSTOM_TRANSACTION = 'custom transaction',
  REJECT_PROPOSAL = 'reject proposal'
}

export enum PROPOSAL_STATES {
  VOTING = 'voting',
  EXECUTED = 'executed'
}

export interface Proposal {
  id: number
  type: PROPOSAL_TYPES
  address?: string
  transferTo?: string
  amountOfFunds?: number
  byteCode?: string
  dateCreated: number
  gnosisURL: string
  existanceTimeInDays?: number
  isDeployed: boolean
  safeTxHash: string
  status: PROPOSAL_STATES
  threshold?: number
  tokenSymbol?: string
  nonce?: string

  userId: number
  groupId: number
}

export type ProposalListItem = Pick<
  Proposal,
  'id' | 'type' | 'dateCreated' | 'address' | 'amountOfFunds' | 'tokenSymbol' | 'nonce'
> & {
  user?: User
  confirmations: number
  confirmedByUser: boolean
  canBeExecuted: boolean
  canBeRejected: boolean
  rejectedForId?: number
}

export type ProposalPage = Pick<
  Proposal,
  | 'id'
  | 'type'
  | 'dateCreated'
  | 'gnosisURL'
  | 'address'
  | 'amountOfFunds'
  | 'threshold'
  | 'tokenSymbol'
  | 'nonce'
> & {
  creator: Pick<User, 'username'>
  user?: User
  groupTitle: string
  confirmedBy: User[]
  numberInQueue: number
  description: string

  confirmedByUser: boolean
  canBeExecuted: boolean
}
