import React from 'react'
import { TailSpin } from 'react-loader-spinner'

import Button from './Button'
import { Context } from './Store'

const Popup: React.FC = () => {
  const { popup } = React.useContext(Context)

  return !popup ? (
    <div />
  ) : (
    <div className="Popup">
      <div className="container h-100 d-flex flex-column align-items-center">
        {popup.text && <div className="Popup__text my-auto">{popup.text}</div>}
        {popup.button &&
          (popup.button.color === 'green' ? (
            <Button green className="mt-auto" onClick={() => popup.button?.onClick?.()}>
              {popup.button.label}
            </Button>
          ) : (
            <Button pink className="mt-auto" onClick={() => popup.button?.onClick?.()}>
              {popup.button.label}
            </Button>
          ))}
        {popup.showLoader && (
          <TailSpin
            height="80"
            width="80"
            color="#01FF83"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="Loader__spinner"
            visible={true}
          />
        )}
      </div>
    </div>
  )
}

export default Popup
