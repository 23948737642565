import React from 'react'

import Context from '../components/Store/Context'
import Member from '../components/Member'
import { isWebApp, IS_AUTHORIZED_KEY, loginURLkey } from '../components/Store/auth'
import { deleteReq } from './ConnectMetamask/helpers/api'
import CopyField from '../components/CopyField'


declare let window: any


const Profile = () => {
  const { user, setState, setIsAuthorized, wallet, isAuthorized } = React.useContext(Context)
  const disconnectTelegram = async () => {
    window.localStorage.removeItem(loginURLkey)
    setState({ user: undefined })
  }
  const disconnectMetamsk = async () => {
    window.localStorage.setItem(IS_AUTHORIZED_KEY, false)
    await deleteReq('/walletConnectSessions')
    setIsAuthorized(false)
  }

  return !user ? <div /> :
    <div className='container'>
      <Member
        user={user}
        className='mb-10 me-0'
      />
      <CopyField label="Your address">
        {user.address}
      </CopyField>
      {!isWebApp() &&
        <div className="my-20 green-link" onClick={disconnectTelegram}>
          Disconnect Telegram from browser
        </div>
      }
      {(!isWebApp() && window.ethereum) &&
        <div className="my-20 green-link" onClick={disconnectMetamsk}>
          Disconnect Metamask extension
        </div>
      }
      {(isAuthorized && wallet) &&
        <p>Connected: {wallet.type}</p>
      }
    </div>
}

export default Profile
