import React from 'react'

import { useLocation } from 'react-router-dom'

class ScrollToTop extends React.Component<{ location: any; children: any }> {
  componentDidUpdate(prevProps: { location: any }) {
    return (
      this.props.location.pathname !== prevProps.location.pathname &&
      window.scrollTo(0, 0)
    )
  }

  render = () => this.props.children
}

export default ({ children }: { children: any }) => {
  const location = useLocation()
  return <ScrollToTop location={location}>{children}</ScrollToTop>
}
