import React, { useEffect } from 'react'

import toast from 'react-hot-toast'

import Button from '../components/Button'
import GroupItem from '../components/GroupItem'
import { LoadingScreen } from '../components/Loader'
import { useQuery } from '../components/Store'
import { GroupPreview } from '../components/Store/Types/models/Group'
import Link from '../components/Link'

const Groups: React.FC = () => {
  const {
    data: groups,
    refetch,
    error
  } = useQuery<{
    pending: GroupPreview[]
    daos: GroupPreview[]
    registered: GroupPreview[]
  }>('/groups')

  useEffect(() => {
    if (error) {
      toast.error(error?.response?.data?.message || error?.message)
    }
  }, [error])

  const botPath = process.env.REACT_APP_BOT_NAME?.replace('@', '')
  const isLoading = groups === null
  const allGroups = !groups
    ? []
    : [...groups.daos, ...groups.pending, ...groups.registered]

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <div className="container d-flex flex-column">
      {allGroups.length === 0 && (
        <div>
          <h5 className={'mb-10'}>No groups are available</h5>
          <p>
            To add a group here add the bot{' '}
            <Link className={'underline text-primary'} to={`https://t.me/${botPath}?'`}>
              {process.env.REACT_APP_BOT_NAME}
            </Link>{' '}
            to a Telegram channel
          </p>
        </div>
      )}
      {groups.pending.length > 0 && (
        <>
          <div className="d-flex flex-row mb-10">
            <h3 className="h3 me-auto">Chats pending your initialization</h3>
            <h3 className="h3 color-light-gray">{groups.pending.length}</h3>
          </div>
          <h5 className="h5 mb-20 w-80">
            You are the group owner of the following chats pending initialization
          </h5>
          {groups.pending.map((group) => (
            <GroupItem key={group.id} group={group} type="pending" />
          ))}
        </>
      )}
      {groups.registered.length > 0 && (
        <>
          <div className="d-flex flex-row mb-10">
            <h3 className="h3 me-auto">Chats pending initialization</h3>
            <h3 className="h3 color-light-gray">{groups.pending.length}</h3>
          </div>
          <h5 className="h5 mb-20 w-90">
            You are registered in the following chats. Wait for initialization by chat
            owners
          </h5>
          {groups.registered.map((group) => (
            <GroupItem key={group.id} group={group} type="registered" />
          ))}
        </>
      )}
      {groups.daos.length > 0 && (
        <>
          <div className="d-flex flex-row mt-37 mb-20">
            <h3 className="h3 me-auto">My DAOs</h3>
            <h3 className="h3 color-light-gray">{groups.daos.length}</h3>
          </div>
          {groups.daos.map((group) => (
            <GroupItem key={group.id} group={group} type="DAO" />
          ))}
        </>
      )}
      <Button green className="mt-auto" onClick={refetch}>
        Refresh
      </Button>
    </div>
  )
}

export default Groups
