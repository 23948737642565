import React from 'react'

import { Navigate, useParams } from 'react-router-dom'

import { useQuery } from '../../hooks/useAsync'
import { LoadingScreen } from '../../components/Loader'
import useTitle from '../../hooks/useTitle'
import { Group as GroupModel } from '../../components/Store/Types/models/Group'
import Initialized from './Initialized'
import Pending from './Pending'
import { Context } from '../../components/Store'


type ParamsType = {
  id: string
}


const Group: React.FC = () => {
  const { user } = React.useContext(Context)
  const { id } = useParams<ParamsType>()
  const { data: group, error } = useQuery<GroupModel>(`/groups/${id}`)
  const groupIsDAO = !!group?.DAOaddress

  useTitle(group?.title)

  if (error) {
    return (
      <div className={'my-20'}>
        {error.message}
      </div>
    )
  }

  return !group ?
    <LoadingScreen />
    :
    groupIsDAO ?
      <Initialized group={group} />
      :
      group.ownerId === user?.id ?
        <Navigate to={`/create-dao/${group.id}`} />
        :
        <Pending group={group} />
}


export default Group
