import React from 'react'

type DividerProps = {
  className?: string
}

const Divider: React.FC<DividerProps> = ({ className }) => (
  <div className={`Divider ${className}`} />
)

export default Divider
