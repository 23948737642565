import { parseEther } from 'ethers/lib/utils'

const MIN_VALUE_IN_ETH = '0.00001'
const MAX_VALUE_IN_ETH = '1000'
const MIN_BALANCE_VALUE = parseEther(MIN_VALUE_IN_ETH)
const MAX_BALANCE_VALUE = parseEther(MAX_VALUE_IN_ETH)

const prettifyBalance = (balanceInEthers: string) => {
  const balance = parseEther(balanceInEthers)

  if (balance.isZero()) {
    return `0`
  }

  if (balance.lt(MIN_BALANCE_VALUE)) {
    return `< ${MIN_VALUE_IN_ETH}`
  }

  if (balance.gt(MAX_BALANCE_VALUE)) {
    return `${MAX_VALUE_IN_ETH}+`
  }

  return balanceInEthers
}

export default prettifyBalance
