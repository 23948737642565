import React, { useState } from 'react'

import { useNavigate, useParams, useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'

import { parseEther } from 'ethers/lib/utils'
import { SEARCH_SCOPE } from '../components/UserSearch'
import { MemberItem } from '../components/Store/Types/models/User'
import Title from '../components/Title'
import Button from '../components/Button'
import { useGet } from '../hooks/useAsync'
import { PROPOSAL_TYPES } from '../components/Store/Types/models/Proposal'
import { Context } from '../components/Store'
import { Group as GroupType } from '../components/Store/Types/models/Group'
import { LoadingScreen } from '../components/Loader'
import TextArea from '../components/TextArea'
import { post } from './ConnectMetamask/helpers/api'
import renderOpenMetamaskNotification from './common/RenderOpenMetamaskNotification'
import TokenSelector from '../components/TokenSelector'
import UserSelector from '../components/UserSelector'
import { ETH_TOKEN_NAME } from '../consts'
import useTitle from '../hooks/useTitle'
import { Asset } from '../components/Store/Types/models/Asset'

export type TransferFundsProps = {
  group: GroupType
  tokenSymbolFromParams: string | undefined
}

const TransferFunds: React.FC<TransferFundsProps> = ({
  group,
  tokenSymbolFromParams
}) => {
  const nonZeroFunds = group.DAObalance.filter((daoAsset) => !daoAsset.isZeroBalance)

  if (nonZeroFunds.length === 0) {
    toast.error(`You can't transfer funds because the balance is zero`)
    return <div />
  }

  const [asset, _setAsset] = React.useState(
    nonZeroFunds.find((daoAsset) => daoAsset.tokenSymbol === tokenSymbolFromParams) ||
      nonZeroFunds[0]
  )
  const navigate = useNavigate()
  const location = useLocation()
  const setAsset = (newAsset: Asset) => {
    navigate(
      { pathname: location.pathname.replace(asset.tokenSymbol, newAsset.tokenSymbol) },
      { replace: true }
    )
    _setAsset(newAsset)
  }
  if (tokenSymbolFromParams !== asset.tokenSymbol) {
    navigate({ pathname: `${location.pathname}/${asset.tokenSymbol}` }, { replace: true })
  }
  useTitle(`${group?.title} transfer ${asset.tokenSymbol}`)

  const [loading, setLoading] = useState(false)
  const [user, setUser] = React.useState<MemberItem | null>(null)
  const [amountOfFunds, setAmountOfFunds] = React.useState(0)
  const [description, setDescription] = React.useState('')
  const {
    openPopup,
    closePopup,
    updatePopup,
    wallet,
    user: me
  } = React.useContext(Context)

  function isValidAmountToTransfer(): boolean {
    try {
      const amountToTransfer = parseEther(amountOfFunds.toString())
      return (
        amountOfFunds > 0 &&
        !amountToTransfer.isZero() &&
        amountToTransfer.lte(parseEther(asset.balanceInEthers))
      )
    } catch (err) {
      return false
    }
  }

  async function transferToken() {
    try {
      setLoading(true)
      openPopup({
        text: <p style={{ textAlign: 'center' }}>{renderOpenMetamaskNotification()}</p>
      })

      const isEthersTrasfer = asset.tokenSymbol === ETH_TOKEN_NAME
      const newProposal = {
        type: isEthersTrasfer
          ? PROPOSAL_TYPES.TRANSFER_FUNDS
          : PROPOSAL_TYPES.CONTRACT_INTERACTION,
        groupId: parseInt(`${group.id}`, 10),
        transferTo: user?.address,
        amountOfFunds,
        tokenSymbol: asset.tokenSymbol,
        description
      }

      const { data }: any = await post(`/proposals/buildProposalPayload`, newProposal)
      const { payload, contractTransactionHash: hash } = data

      const signature = await wallet.signMessage([me?.address, hash])

      updatePopup({
        showLoader: true
      })

      await post('/proposals/create', {
        proposal: newProposal,
        proposalPayload: payload,
        hash,
        signature
      })
      setLoading(false)
      closePopup()
      navigate(`/proposals/${group.id}/1`, { replace: true })
    } catch (error: any) {
      setLoading(false)
      closePopup()
      toast.error(error?.response?.data?.message || error?.message)
    }
  }

  return !group ? (
    <div />
  ) : (
    <div className="container d-flex flex-column">
      <Title title={group.title} className="mb-10" />
      <h2 className="h2 mb-33">TRANSFER {asset.tokenSymbol}</h2>

      <UserSelector
        user={user}
        onChange={setUser}
        groupId={group.id}
        scope={SEARCH_SCOPE.ALL}
        label={`Add receiver's`}
        className="mb-20"
      />

      <TokenSelector
        assets={group.DAObalance}
        token={asset}
        setToken={setAsset}
        amount={amountOfFunds}
        setAmount={setAmountOfFunds}
        className="mb-20"
        label="Token & amount"
      />

      <TextArea
        value={description}
        onChange={setDescription}
        className={`mb-20 ${description.length > 0 && 'TextArea--accepted'}`}
        label="Description"
        placeholder={`I propose to send ${asset.tokenSymbol} because...`}
      />

      <Button
        pink
        disabled={loading || !user || !isValidAmountToTransfer()}
        onClick={transferToken}
        className="mt-auto"
      >
        Create proposal
      </Button>
    </div>
  )
}

type IParams = {
  groupId: string
  tokenSymbol: string | undefined
}

export default () => {
  const { groupId, tokenSymbol } = useParams<IParams>()
  const group: GroupType | null = useGet(`/groups/${groupId}`)
  return !group ? (
    <LoadingScreen />
  ) : (
    <TransferFunds group={group} tokenSymbolFromParams={tokenSymbol} />
  )
}
