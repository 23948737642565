import React from 'react'

import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router'

import Title from '../../components/Title'
import Member from '../../components/Member'
import MembersList from '../../components/MembersList'
import { Group } from '../../components/Store/Types/models/Group'
import { Context } from '../../components/Store'
import { post } from '../../pages/ConnectMetamask/helpers/api'
import { getAuthObject } from '../../components/Store/auth'


type PendingProps = {
  group: Group
}


const Pending: React.FC<PendingProps> = ({ group }) => {
  let { user } = React.useContext(Context)
  const isUserInGroup = !!group.users.find(userInGroup => userInGroup.id === user?.id)
  const owner = group?.users.find(user => user.id === group.ownerId)
  const navigate = useNavigate()

  // const registerUserInGroup = async () => {
  //   if (!user) throw new Error('No user is authorized to the browser')
  //   try {
  //     await post('/registration', {
  //       userTelegramId: user.id,
  //       address: user.address,
  //       groupId: group.id
  //     })
  //     navigate('/refresh')
  //     setTimeout(() => navigate(-1), 50)
  //   } catch (err: any) {
  //     toast.error(err?.response?.data?.message || err?.message)
  //   }
  // }
  // React.useEffect(() => {
  //   if (!isUserInGroup)
  //     registerUserInGroup()
  // }, [])


  return (
    <div className='container d-flex flex-column'>
      <Title title={group.title} users={group.users.length} className="mb-15" />
      <p>
        Wait while
        <Member
          user={owner || {}}
          inline
        />
        finishes DAO initialization
      </p>
      <h3 className='h3 mt-auto mb-5'>
        Registered members
      </h3>
      <MembersList
        group={group}
        addButton
        dropdown
        className="mb-auto"
      />
    </div>
  )
}


export default Pending
