import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import toast from 'react-hot-toast'
import { useQuery } from '../components/Store'
import { Group } from '../components/Store/Types/models/Group'
import Input from '../components/Input'
import Button from '../components/Button'
import { put } from './ConnectMetamask/helpers/api'
import useTitle from '../hooks/useTitle'

function AddDAO({ group }: { group: Group }) {
  const [DAOaddress, setDAOAddress] = useState('')
  const navigate = useNavigate()

  async function addDAOHandler() {
    try {
      await put(`/groups/${group.id}/link-existing-dao`, { DAOaddress })
      navigate(`/groups/${group.id}`)
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message)
    }
  }

  return (
    <div className="container">
      <Input
        value={DAOaddress}
        onChange={setDAOAddress}
        className="mb-20"
        label="Enter existing DAO address"
        placeholder="0x8976263712xx..x1"
      />
      <Button disabled={!DAOaddress} onClick={addDAOHandler} green>
        Apply
      </Button>
    </div>
  )
}

type ParamsType = {
  groupId: string
}

export default () => {
  const { groupId = '' } = useParams<ParamsType>()
  const { data: group } = useQuery<Group>(`/groups/${groupId}`)
  useTitle(group?.title)

  return <AddDAO group={group} />
}
