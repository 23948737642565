import QRCodeModal from '@walletconnect/qrcode-modal'
import { isMobile } from 'react-device-detect'

declare let window: any

const walletConnectParamsAndroid = {
  bridge: 'https://bridge.walletconnect.org', // Required
  qrcodeModal: {
    ...QRCodeModal,
    open(uri: string, cb: any, opts: any) {
      if (isMobile) {
        window.open(uri, '_self')
      } else {
        QRCodeModal.open(uri, cb, opts)
      }
    }
  },
  qrcodeModalOptions: {
    desktopLinks: [],
    mobileLinks: ['metamask']
  },
  clientMeta: {
    description: 'ninDAO DApp',
    url: 'https://nindao.xyz',
    icons: ['https://walletconnect.org/walletconnect-logo.png'], // default icon TODO: replace with our
    name: 'ninDAO'
  }
}

const walletConnectParamsIOS = {
  bridge: 'https://bridge.walletconnect.org', // Required
  qrcodeModal: QRCodeModal,
  clientMeta: {
    description: 'ninDAO DApp',
    url: 'https://nindao.xyz',
    icons: ['https://walletconnect.org/walletconnect-logo.png'], // default icon TODO: replace with our
    name: 'ninDAO'
  }
}

export { walletConnectParamsAndroid, walletConnectParamsIOS }
