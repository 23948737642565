import toast from 'react-hot-toast'
import { isMobile } from 'react-device-detect'
import { getBrowserAuthObject, IS_AUTHORIZED_KEY } from '../../components/Store/auth'

declare let window: any

function MetaMask() {
  async function connect(callback: any) {
    const authObject = getBrowserAuthObject()

    if (!authObject?.id) {
      toast.error('This page was opened not with Telegram "connect wallet" button')
      return
    }

    if (window.ethereum?.isMetaMask) {
      callback()
    }
  }

  async function disconnect() {
    console.log('disconnect')
    window.location.reload()
  }

  function sendTransaction(transaction: any) {
    return window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transaction]
    })
  }

  function bindEvents() {
    window.ethereum?.on('disconnect', disconnect)
    window.ethereum?.on('accountsChanged', (accounts: Array<string>) => {
      // disconnect event doesn't work, use this workaround instead
      if (accounts.length === 0) {
        window.localStorage.setItem(IS_AUTHORIZED_KEY, false)
        window.location.reload()
      }
    })

    if (isMobile) {
      // display wallet connect modal to get a link for metamask on mobile devices
      // connect(() => {})
    }
  }

  function signMessage(args: any[]) {
    return window.ethereum
      .request({
        method: 'eth_sign',
        params: args
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  bindEvents()

  return {
    type: 'MetaMask extension',
    connect,
    disconnect,
    sendTransaction,
    signMessage
  }
}
export default MetaMask()
