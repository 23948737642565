import React from 'react'
import toast from 'react-hot-toast'
import copyToClipboard from '../utils/copyToClipboard'

import { ReactComponent as CopyIcon } from '../styles/img/copy.svg'

export type CopyFieldPropsType = {
  className?: string
  children: string
  label?: string
}

const CopyField: React.FC<CopyFieldPropsType> = ({ className = '', children, label }) => {
  function copy() {
    copyToClipboard(children)
    toast('Copied', {
      duration: 750,
      style: { zIndex: 10001 }
    })
  }

  return (
    <div className={className} onClick={copy}>
      <label className={'form-label'} htmlFor=''>
        {label}
      </label>
      <div className='CopyField'>
        <div className='CopyField__text'>
          {children}
        </div>
        <CopyIcon className='CopyIcon' />
      </div>
    </div>
  )
}

export default CopyField
