import { useEffect, useState } from 'react'
import { get } from '../../pages/ConnectMetamask/helpers/api'
import MetaMask from './MetaMask'
import WalletConnect from './WalletConnect'

const metaMaskWallet = MetaMask
const walletConnectWallet = WalletConnect

declare let window: any

function useWallet(isAuthorized: any): any {
  const [wallet, setWallet] = useState({})

  useEffect(() => {
    const isMetaMaskConnected = async function () {
      if (!window.ethereum) return Promise.reject()

      try {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' })

        if (accounts[0]) {
          return await Promise.resolve()
        }
        return await Promise.reject()
      } catch (error) {
        return Promise.reject()
      }
    }

    if (isAuthorized) {
      isMetaMaskConnected()
        .then(() => {
          setWallet(metaMaskWallet)
        })
        .catch(() => {
          try {
            get<any>(`/walletConnectSessions`).then((res) => {
              const existingWalletConnectSession = res.data

              if (existingWalletConnectSession) {
                walletConnectWallet.setExistingSession(existingWalletConnectSession)
              }
            })
          } finally {
            setWallet(walletConnectWallet)
          }
        })
    }
  }, [isAuthorized])

  return {
    ...wallet,
    connectWalletConnect: (callback: any) => {
      setWallet(walletConnectWallet)
      return walletConnectWallet.connect(callback)
    },
    connectMetaMask: (callback: any) => {
      setWallet(metaMaskWallet)
      return metaMaskWallet.connect(callback)
    }
  }
}

export default useWallet
