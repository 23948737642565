import React from 'react'

import { useLocation, useNavigate } from 'react-router'

import { Context } from './Store'
import Divider from './Divider'
import Avatar from './Avatar'
import { getAuthObject } from './Store/auth'
import printUsername from '../utils/printUsername'
import LinkWrapper from './Link'

type HeaderProps = {
  className?: string
}

const Header: React.FC<HeaderProps> = ({ className = '' }) => {
  const { console: _console } = React.useContext(Context)
  const userFromBrowser = getAuthObject()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div className={`Header ${className}`}>
      <div className="container">
        {userFromBrowser && (
          <div className="d-flex flex-row">
            {(window.history.length > 2 && location.pathname !== '/groups') &&
              <div
                className='green-link'
                onClick={() => navigate(-1)}
              >
                &#60; Back
              </div>
            }
            {Object.keys(userFromBrowser).includes('id') &&
              <LinkWrapper to='/profile' className='ms-auto green-link'>
                <div className="d-flex flex-row align-items-center font-inherit">
                  {printUsername(userFromBrowser)}
                  <Avatar img={userFromBrowser.photo_url} className="ms-10 my-5" />
                </div>
              </LinkWrapper>
            }
          </div>
        )}
        {process.env.NODE_ENV === 'development' &&
          _console.map((log, index) => (
            <div key={index}>
              <br />
              {log}
            </div>
          ))}
      </div>
      <Divider />
    </div>
  )
}

export default Header
