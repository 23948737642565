import React from 'react'
import Avatar from './Avatar'

import { MemberItem } from './Store/Types/models/User'

import { ReactComponent as Cross } from '../styles/img/cross.svg'

type MemberProps = {
  user: MemberItem
  editable?: boolean
  simple?: boolean
  inline?: boolean
  onClick?: () => void
  className?: string
}

const Member: React.FC<MemberProps> = ({
  user,
  editable,
  simple,
  inline,
  onClick,
  className
}) => (
  <div
    className={`
    Member
    ${simple && 'Member--simple'}
    ${inline && 'Member--inline'}
    ${className}
  `}
  >
    {user.username && (
      <>
        <Avatar img={user.avatar} className="Member__avatar" />
        <div className="Member__username">{user.username}</div>
      </>
    )}
    {!user.username && user.address && (
      <div className="Member__address">{user.address}</div>
    )}
    {editable && <Cross onClick={onClick} className="Member__cross" />}
  </div>
)

export default Member
