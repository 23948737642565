import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'

import {isMobile, isAndroid} from 'react-device-detect'
import toast from 'react-hot-toast'

import Button from '../../components/Button'
import Link from '../../components/Link'
import {Context} from '../../components/Store'
import {
  checkAuth,
  getAuthObject,
  isWebApp,
  IS_AUTHORIZED_KEY,
  getAddressFromMetamaskExtension
} from '../../components/Store/auth'
import {post} from './helpers/api'
import {
  getMetamaskLink,
  removeWalletConnectWidget
} from './helpers/handleWalletConnectWidget'

import {ReactComponent as MetamaskIcon} from '../../styles/img/metamask.svg'
import logo from '../../styles/img/logo.png'

declare let window: any
declare let document: any

const ConnectMetamask = () => {
  const {fetchUser, wallet, setIsAuthorized} = useContext(Context)
  const {connectMetaMask, connectWalletConnect} = wallet
  const [metamaskLink, setMetamaskLink] = useState<string | undefined>(undefined)
  const groupId = window.localStorage.getItem('groupId')
  const ref = useRef(null)

  const handleMetaMaskLink = useCallback((event: any) => {
    setMetamaskLink(event.detail)
  }, [])

  useEffect(() => {
    let element: any

    if (ref.current) {
      element = document.getElementById('wallet-container')
      element.addEventListener('MetaMaskLinkReady', handleMetaMaskLink, false)
    }

    return () => {
      element?.removeEventListener('MetaMaskLinkReady', handleMetaMaskLink)
    }
  }, [ref])

  async function registerUserInGroup(address: string | null) {
    if (!address || !groupId) {
      return
    }
    const user = getAuthObject()

    try {
      if (!user) throw new Error('No user is authorized to the browser')
      await post('/registration', {
        userTelegramId: user.id,
        address,
        groupId
      })
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message)
    }
  }

  async function walletConnectCallback(session: any) {
    try {
      await post('/walletConnectSessions', session)
      await registerUserInGroup(session.accounts[0])
      removeWalletConnectWidget()
      await fetchUser()
      window.localStorage.setItem(IS_AUTHORIZED_KEY, true)
      setIsAuthorized(true)
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message)
    }
  }

  async function metaMaskCallback() {
    await registerUserInGroup(await getAddressFromMetamaskExtension())
    await fetchUser()
    window.localStorage.setItem(IS_AUTHORIZED_KEY, true)
    setIsAuthorized(true)
  }

  useEffect(() => {
    let intervalId: any

    checkAuth()
      .catch(() => {
        // When not authorized, display a wallet connect modal to get a link for metamask on mobile devices
        if (isMobile && !isAndroid) {
          connectWalletConnect(walletConnectCallback)
          intervalId = setInterval(async () => {
            const walletConnectElem = document.getElementById('walletconnect-wrapper')
            const $walletContainer = document.getElementById('wallet-container')
            const link = getMetamaskLink()

            if (link) {
              const event = new CustomEvent('MetaMaskLinkReady', {
                // @ts-ignore;
                detail: link
              })
              $walletContainer.dispatchEvent(event)
              walletConnectElem?.classList.add('d-none')
              clearInterval(intervalId)
            }
          }, 200)
        }
      })

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [])

  return !isWebApp() && !groupId ? (
    <div className="container text-center">No groupId provided</div>
  ) : (
    <div className="container d-flex flex-column justify-content-center align-items-center">
      <img src={logo} className="w-70 mb-55" alt="" />
      {isMobile && (
        <>
          {isAndroid ? (
            <Button
              green
              onClick={async () => connectWalletConnect(walletConnectCallback)}
            >
              Connect your Metamask <MetamaskIcon className="MetamaskIcon ms-12" />
            </Button>
          ) : (
            <div ref={ref} id="wallet-container">
              <Link to={metamaskLink} disabled={!metamaskLink}>
                <Button green disabled={!metamaskLink}>
                  {metamaskLink ? (
                    <>
                      Connect Metamask <MetamaskIcon className="MetamaskIcon ms-12" />
                    </>
                  ) : (
                    'Loading...'
                  )}
                </Button>
              </Link>
            </div>
          )}
        </>
      )}
      {!isMobile && (
        <>
          {!isWebApp() &&
            (!window.ethereum ? (
              <p className="mb-20 text-center w-80">
                Install <Link to="https://metamask.io">Metamask browser extension</Link>{' '}
                or scan QR code with mobile Metamask
              </p>
            ) : (
              <Button
                green
                className="w-80 mb-20"
                onClick={async () => {
                  await connectMetaMask(metaMaskCallback)
                }}
              >
                Connect to Metamask <MetamaskIcon className="MetamaskIcon ms-12" />
              </Button>
            ))}
          <Button
            pink
            className="w-80"
            onClick={() => connectWalletConnect(walletConnectCallback)}
          >
            QR Code Scan
          </Button>
        </>
      )}
    </div>
  )
}

export default ConnectMetamask
