import { User } from './User'
import { Proposal } from './Proposal'
import { Asset } from './Asset'

export enum GROUP_STATE {
  REGISTRATION_IS_OPEN = 'registration is open',
  DAO_INITIALIZATION = 'DAO initialization',
  DAO_CREATED = 'DAO created',
}

export interface Group {
  id: number
  title: string
  gnosisUrl?: string
  DAOaddress: string
  DAObalance: Asset[]
  numberOfProposals: number
  stakeholders: string[]
  threshold: number
  state: GROUP_STATE
  users: Partial<User>[]
  proposals?: Proposal[]

  ownerId: number
}

export interface GroupPreview {
  id: number
  name: string
  usersCount: number
  createdAt: number
  userPreviews: [Partial<User>, Partial<User>]
}

export interface GroupCreate {
  id: number
  title: string
  DAOaddress?: string
  numberOfProposals?: number
  DAObalance?: number
  users?: Partial<User>[]
  state?: GROUP_STATE
  ownerId?: number
}
