import React from 'react'

import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useSearchParams
} from 'react-router-dom'

import routes from '../utils/routes'
import { Context } from './Store'
import { getAuthObject, getAuthStringFromBrowser, getBrowserAuthObject, loginURLkey } from './Store/auth'
import TitleWrapper from './TitleWrapper'


const ProtectedRoutes: React.FC = () => {
  const {
    setState,
    isAuthorized,
    pageToReturnAfterLogin
  } = React.useContext(Context)
  const { pathname } = useLocation()
  const [ searchParams ] = useSearchParams()
  // const groupIdFromSearchParams = searchParams.get('groupId')
  const groupIdFromSearchParams = window.localStorage.getItem('groupId')
  const unprotectedRoutes = ['/connect-metamask', '/connect-telegram']

  // Remove when nin-js-bot-tg link is changed in utils/getLoginURL
  if (pathname === '/' && groupIdFromSearchParams) {
    return <Navigate to={`/groups/${groupIdFromSearchParams}`} />
  }

  if (!isAuthorized && !unprotectedRoutes.includes(pathname)) {
    setState({ pageToReturnAfterLogin: pathname })
    const authObject = getAuthObject()
    return <Navigate to={authObject ? '/connect-metamask' : '/connect-telegram'} />
  }

  if (isAuthorized) {
    if (pageToReturnAfterLogin) {
      const _pageToReturnAfterLogin = pageToReturnAfterLogin.slice()
      setState({ pageToReturnAfterLogin: undefined })
      if (_pageToReturnAfterLogin !== pathname)
        return <Navigate to={_pageToReturnAfterLogin} />
    }
    if (unprotectedRoutes.includes(pathname)) {
      return <Navigate to={'/'} />
    }
  }

  return (
    <Routes>
      {routes.map((route) => (
          <Route
            key={route.to}
            path={route.to}
            element={
              <TitleWrapper title={route.title}>
                {route.Comp}
              </TitleWrapper>
            }
          />
      ))}
      <Route
        path="*"
        element={
          <Navigate
            to={{
              pathname: '/groups',
              search: window.location.search
            }}
            replace
          />
        }
      />
    </Routes>
  )
}


export default ProtectedRoutes
