import React from 'react'

import { MemberItem } from './Store/Types/models/User'
import Avatar from './Avatar'

type MembersIconsProps = {
  users: MemberItem[]
  className?: string
}

const MembersIcons: React.FC<MembersIconsProps> = ({ users = [], className }) => {
  const [first, second] = users
  return (
    <div className={`MembersIcons ${className}`}>
      <Avatar
        img={second?.avatar}
        className={`${second || 'opacity-0'}`}
      />
      <Avatar
        img={first?.avatar}
        className="MembersIcons__item"
      />
      <Avatar
        number={users.length}
        className="MembersIcons__item"
      />
    </div>
  )
}

export default MembersIcons
