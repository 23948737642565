import React from 'react'

import Button from '../components/Button'

import { ReactComponent as MetamaskIcon } from '../styles/img/telegram.svg'
import logo from '../styles/img/logo.png'
import Link from '../components/Link'

const ConnectTelegram = () => {
  const botPath = process.env.REACT_APP_BOT_NAME?.replace('@', '')

  return (
    <div className="container d-flex flex-column justify-content-center align-items-center">
      <img src={logo} className="w-70 mb-55" alt="" />
      <p>
        <Link to={`https://t.me/${botPath}?'`}>
          <Button green>
            Login with Telegram <MetamaskIcon className="TelegramIcon ms-10" />
          </Button>
        </Link>
      </p>
    </div>
  )
}

export default ConnectTelegram
