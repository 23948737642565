import React from 'react'
import ReactDOM from 'react-dom/client'

import { createGlobalStyle } from 'styled-components'

import { HashRouter } from 'react-router-dom'
import App from './App'
import { globalStyle } from './pages/ConnectMetamask/styles'
import { Provider } from './components/Store'

const GlobalStyle = createGlobalStyle`
  ${globalStyle}
`

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <HashRouter>
      <Provider>
        <App />
      </Provider>
    </HashRouter>
  </React.StrictMode>
)
