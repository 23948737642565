import React from 'react'

import { useParams } from 'react-router-dom'

import Button from '../components/Button'
import Link from '../components/Link'
import { Group as GroupType } from '../components/Store/Types/models/Group'
import { useGet } from '../components/Store'
import { LoadingScreen } from '../components/Loader'
import { Asset } from '../components/Store/Types/models/Asset'
import useTitle from '../hooks/useTitle'

type ComponentProps = {
  group: GroupType
}

const isUserCanTransferFunds = (daoAssets: Asset[]): boolean => {
  return (
    daoAssets && daoAssets.length > 0 && daoAssets.some((asset) => !asset.isZeroBalance)
  )
}

const NewProposal: React.FC<ComponentProps> = ({ group }) => {
  const canTransferFunds = isUserCanTransferFunds(group?.DAObalance)
  const canRemoveMember = group.users?.length > 1

  return (
    <div className="NewProposal">
      <div className='container d-flex flex-column'>
        {canTransferFunds && (
          <Link
            to={`/${group.id}/new-proposal/transfer-funds`}
            className="d-block mb-10"
          >
            <Button pink>Transfer funds</Button>
          </Link>
        )}
        <Link
          to={`/${group.id}/new-proposal/add-participant`}
          className="d-block mb-10"
        >
          <Button pink>Add member</Button>
        </Link>
        {canRemoveMember && (
          <Link
            to={`/${group.id}/new-proposal/remove-participant`}
            className="d-block mb-10"
          >
            <Button pink>Remove member</Button>
          </Link>
        )}
      </div>
    </div>
  )
}

type IParams = {
  groupId: string
}

export default () => {
  const { groupId } = useParams<IParams>()
  const group: GroupType | null = useGet(`/groups/${groupId}`)
  useTitle(group?.title + ' new proposal')

  return !group ? <LoadingScreen /> : <NewProposal group={group} />
}
