import React from 'react'

import format from 'date-fns/format'

import Avatar from './Avatar'
import { User } from './Store/Types/models/User'


type MessageProps = {
  user: User
  text: string
  date?: number
  className?: string
}


const Message: React.FC<MessageProps> = ({
  user,
  text,
  date,
  className
}) => (
  <div className={`Message ${className}`}>
    <Avatar img={user.avatar} />
    <div className='Message__body'>
      <div className='Message__body__sender'>
        {user.username}
      </div>
      <div className='Message__body__text'>
        {text}
      </div>
      {date &&
        <div className='Message__body__date'>
          {format(new Date(date), 'HH:mm dd.MM.yy')}
        </div>
      }
    </div>
  </div>
)


export default Message
