import React, { useContext, useState } from 'react'

import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'

import { SEARCH_SCOPE } from '../components/UserSearch'
import EnterThreshold from '../components/EnterThreshold'
import { PROPOSAL_TYPES } from '../components/Store/Types/models/Proposal'
import { MemberItem, User } from '../components/Store/Types/models/User'
import Title from '../components/Title'
import proposalTitle from '../utils/proposalTitle'
import Button from '../components/Button'
import { useGet } from '../hooks/useAsync'
import { Group as GroupType } from '../components/Store/Types/models/Group'
import { LoadingScreen } from '../components/Loader'
import { Context } from '../components/Store'
import TextArea from '../components/TextArea'
import { post } from './ConnectMetamask/helpers/api'
import renderOpenMetamaskNotification from './common/RenderOpenMetamaskNotification'
import UserSelector from '../components/UserSelector'
import useTitle from '../hooks/useTitle'

type AddRemoveParticipantProps = {
  group: GroupType
  user?: User | null
  type: PROPOSAL_TYPES.ADD_PARTICIPANT | PROPOSAL_TYPES.REMOVE_PARTICIPANT
}

const AddRemoveParticipant: React.FC<AddRemoveParticipantProps> = ({
  group,
  user: userFromLoginURL,
  type = PROPOSAL_TYPES.ADD_PARTICIPANT
}) => {
  const [user, setUser] = useState<MemberItem | null>(userFromLoginURL || null)
  const [loading, setLoading] = useState(false)
  const [threshold, setThreshold] = useState(group?.threshold || 1)
  const [description, setDescription] = useState('')
  const { openPopup, closePopup, updatePopup, wallet, user: me } = useContext(Context)
  const navigate = useNavigate()

  async function handleParticipant() {
    try {
      const newProposal = {
        type,
        groupId: parseInt(`${group.id}`, 10),
        address: user?.address,
        threshold,
        description
      }

      setLoading(true)

      openPopup({
        text: <p style={{ textAlign: 'center' }}>{renderOpenMetamaskNotification()}</p>,
        button: {
          label: 'Back',
          color: 'pink',
          onClick: () => closePopup()
        }
      })

      const { data }: any = await post(`/proposals/buildProposalPayload`, newProposal)
      const { payload, contractTransactionHash: hash } = data

      const signature = await wallet.signMessage([me?.address, hash])

      updatePopup({
        showLoader: true
      })

      await post('/proposals/create', {
        proposal: newProposal,
        proposalPayload: payload,
        hash,
        signature
      })
      setLoading(false)
      closePopup()
      navigate(`/proposals/${group.id}/1`, { replace: true })
    } catch (error: any) {
      setLoading(false)
      closePopup()
      toast.error(error?.response?.data?.message || error?.message)
    }
  }
  const totalUsers = group?.users?.length || 0

  return (
    <div className="container d-flex flex-column">
      <h3 className="h3">{proposalTitle({ type }, true)}</h3>
      <Title title={group.title} className="mb-33" />

      <UserSelector
        user={user}
        onChange={setUser}
        groupId={group.id}
        scope={
          type === PROPOSAL_TYPES.ADD_PARTICIPANT
            ? SEARCH_SCOPE.ALL_EXCEPT_DAO
            : SEARCH_SCOPE.IN_DAO
        }
        label="Add member"
        className="mb-20"
      />

      <TextArea
        value={description}
        onChange={setDescription}
        className={`mb-20 ${description.length > 0 && 'TextArea--accepted'}`}
        label="Description"
        placeholder={`I propose to ${
          type === PROPOSAL_TYPES.ADD_PARTICIPANT ? 'add' : 'remove'
        } this person because...`}
      />

      <EnterThreshold
        value={threshold}
        min={1}
        max={totalUsers + (type === PROPOSAL_TYPES.ADD_PARTICIPANT ? 1 : -1)}
        onChange={setThreshold}
        label="New voting amount"
        className="mt-auto mb-55"
      />

      <Button pink disabled={loading || !user} onClick={handleParticipant}>
        Create proposal
      </Button>
    </div>
  )
}

type IParams = {
  groupId: string
}

export default ({
  type
}: {
  type: PROPOSAL_TYPES.ADD_PARTICIPANT | PROPOSAL_TYPES.REMOVE_PARTICIPANT
}) => {
  const { groupId } = useParams<IParams>()
  const [searchParams] = useSearchParams()
  const address = searchParams.get('address')

  const group: GroupType | null = useGet(`/groups/${groupId}`)
  const usersWithAddress: User[] = useGet('/users/find', {
    address,
    groupId,
    scope: SEARCH_SCOPE.IN_DAO
  })
  const user: User | null = usersWithAddress?.[0] || null
  useTitle(`${proposalTitle({ type }, true)} ${group?.title}`)

  return !group ? (
    <LoadingScreen />
  ) : (
    <AddRemoveParticipant group={group} user={user} type={type} />
  )
}
