import React from 'react'

import axios from 'axios'

import isProbablyAddress from '../utils/isProbablyAddress'
import Input from './Input'
import { MemberItem } from './Store/Types/models/User'
import Member from './Member'
import { getAuthHeader } from './Store/auth'
import { generateURL } from '../hooks/useAsync'

export enum SEARCH_SCOPE {
  ALL = 'all',
  IN_DAO = 'dao',
  ALL_EXCEPT_DAO = 'all except dao'
}

type UserSearchProps = {
  groupId: number
  onChange: (value: MemberItem) => void
  scope?: SEARCH_SCOPE
  label?: string
  className?: string
}

const UserSearch: React.FC<UserSearchProps> = ({
  groupId,
  onChange,
  scope,
  label,
  className
}) => {
  const [string, setString] = React.useState('')
  const [options, setOptions] = React.useState<MemberItem[]>([])

  const updateOptions = async () => {
    setOptions(
      (
        await axios.get(
          generateURL('/users/find', {
            [isProbablyAddress(string) ? 'address' : 'username']: string,
            groupId,
            scope
          }),
          getAuthHeader()
        )
      ).data
    )
  }

  React.useEffect(() => {
    updateOptions()
  }, [string])

  const address = (
    <div
      className={`
        UserSearch__label__item
        ${
          string.length > 0 &&
          (isProbablyAddress(string)
            ? 'UserSearch__label__item--active'
            : 'UserSearch__label__item--disabled')
        }
      `}
    >
      address
    </div>
  )
  const username = (
    <div
      className={`
        UserSearch__label__item
        ${
          string.length > 0 &&
          (!isProbablyAddress(string)
            ? 'UserSearch__label__item--active'
            : 'UserSearch__label__item--disabled')
        }
      `}
    >
      @username
    </div>
  )

  return (
    <div className={`UserSearch ${className}`}>
      {label &&
        <div className="UserSearch__label">
          {label} <span className='font-inherit text-nowrap'>
            {address}/{username}
          </span>
        </div>
      }
      <Input
        value={string}
        onChange={setString}
        placeholder="@username"
      >
        {!options || options.length === 0 || string.length < 3 ? (
          []
        ) : (
          <div className="dropdown">
            {options.map((user, index) => (
              <div
                key={index}
                className="dropdown__item"
                onClick={() => {
                  setString('')
                  onChange(user)
                }}
              >
                <Member user={user} simple className="w-100 m-0" />
              </div>
            ))}
          </div>
        )}
      </Input>
    </div>
  )
}

export default UserSearch
