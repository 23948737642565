import { Asset } from './components/Store/Types/models/Asset'

const ETH_TOKEN_NAME = 'ETH'
const VOID_ETHER = {
  balance: '0',
  tokenSymbol: ETH_TOKEN_NAME,
  balanceInEthers: '0',
  isZeroBalance: true
} as Asset

export { ETH_TOKEN_NAME, VOID_ETHER }
