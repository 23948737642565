import axios, { AxiosInstance } from 'axios'
import { IAssetData, IGasPrices, IParsedTx } from './types'
import { generateURL } from '../../../hooks/useAsync'
import { getAuthHeader } from '../../../components/Store/auth'

const api: AxiosInstance = axios.create({
  baseURL: 'https://ethereum-api.xyz',
  timeout: 30000, // 30 secs
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export async function apiGetAccountAssets(
  address: string,
  chainId: number
): Promise<IAssetData[]> {
  const response = await api.get(`/account-assets?address=${address}&chainId=${chainId}`)
  const { result } = response.data
  return result
}

export async function apiGetAccountTransactions(
  address: string,
  chainId: number
): Promise<IParsedTx[]> {
  const response = await api.get(
    `/account-transactions?address=${address}&chainId=${chainId}`
  )
  const { result } = response.data
  return result
}

export const apiGetAccountNonce = async (
  address: string,
  chainId: number
): Promise<string> => {
  const response = await api.get(`/account-nonce?address=${address}&chainId=${chainId}`)
  const { result } = response.data
  return result
}

export const apiGetGasPrices = async (): Promise<IGasPrices> => {
  const response = await api.get(`/gas-prices`)
  const { result } = response.data
  return result
}

export const get = async <T>(path: string, params = {}) =>
  axios.get<T>(generateURL(path, params), getAuthHeader())

export const post = async <T>(path: string, data: object) =>
  axios.post<T>(generateURL(path), data, getAuthHeader())

export const put = async <T>(path: string, data: object) =>
  axios.put<T>(generateURL(path), data, getAuthHeader())

export const deleteReq = async <T>(path: string) =>
  axios.delete<T>(generateURL(path), getAuthHeader())
