import React from 'react'

import Member from './Member'
import UserSearch, { SEARCH_SCOPE } from './UserSearch'
import { MemberItem } from './Store/Types/models/User'


export type UserSelectorProps = {
  user: null | MemberItem
  onChange: (user: null | MemberItem) => void
  groupId: number
  scope: SEARCH_SCOPE
  className?: string
  label?: string
}


const UserSelector: React.FC<UserSelectorProps> = ({
  user,
  onChange,
  groupId,
  scope,
  className,
  label
}) =>
  <div className={`UserSelector ${className}`}>
    {label &&
      <div className='h4 mb-5'>
        {label}
      </div>
    }
    {user ?
      <div className="d-flex flex-row">
        <Member
          user={user}
          editable
          onClick={() => onChange(null)}
          className='accepted-input mt-4'
        />
      </div>
      :
      <UserSearch
        groupId={groupId}
        onChange={onChange}
        scope={scope}
      />
    }
  </div>


export default UserSelector
