declare let document: any

const getMetamaskLink = () => {
  const elements = document.getElementsByClassName(
    'walletconnect-connect__button__icon_anchor'
  )
  return (Array.from(elements) as HTMLAnchorElement[]).find((elem: any) =>
    elem.href.includes('https://metamask.app.link/')
  )?.href
}

const removeWalletConnectWidget = () =>
  Array.from(document.getElementsByClassName('walletconnect-wrapper')).forEach(
    (elem: any) => elem.remove()
  )

export { getMetamaskLink, removeWalletConnectWidget }