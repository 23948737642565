import React from 'react'

import { useParams } from 'react-router-dom'
import Divider from '../components/Divider'

import { useQuery } from '../components/Store'
import Title from '../components/Title'
import { LoadingScreen } from '../components/Loader'
import useProposals from '../hooks/useProposals'
import ProposalItem from '../components/ProposalItem'
import { ProposalListItem } from '../components/Store/Types/models/Proposal'
import useTitle from '../hooks/useTitle'

type ParamsType = {
  groupId: string
  pageNumber: string
}

type IGroupProposals = {
  proposals: ProposalListItem[]
  threshold: number
  groupTitle: string
  refetch: any
}

const GroupProposals: React.FC<IGroupProposals> = ({
  proposals = [],
  threshold,
  groupTitle,
  refetch
}) => {
  const { loading, execute, vote, reject } = useProposals()

  async function onReject(proposalId: number) {
    await await reject(proposalId)
    await refetch()
  }

  return (
    <div className="GroupProposals">
      <Title title={groupTitle} className="container mb-24" />

      <Divider />
      {proposals.length === 0 && (
        <div className="GroupProposals__proposal">
          <div className="container">
            <p>No proposals available</p>
          </div>
        </div>
      )}
      {proposals
        .map((proposal) => (
          <ProposalItem
            key={proposal.id}
            proposal={proposal}
            threshold={threshold}
            loading={loading}
            vote={vote}
            execute={execute}
            refetch={refetch}
            reject={onReject}
          />
        ))
        .reduce(
          (a: React.ReactNode, b: React.ReactNode, index: number) => [
            a,
            <Divider key={index} />,
            b
          ],
          []
        )}
      <Divider />
    </div>
  )
}

export default () => {
  const { groupId = '', pageNumber } = useParams<ParamsType>()
  const { data, loading, refetch } = useQuery<{
    groupTitle: string
    threshold: number
    proposals: ProposalListItem[]
  }>('/proposals', {
    groupId,
    pageNumber
  })
  const { groupTitle, threshold, proposals = [] } = data || {}
  useTitle(`${groupTitle} proposals`)

  return loading ? (
    <LoadingScreen />
  ) : (
    <GroupProposals
      refetch={refetch}
      proposals={proposals.filter(({ status }: any) => status !== 'executed')}
      threshold={threshold}
      groupTitle={groupTitle}
    />
  )
}
