import React from 'react'

import Link from '../components/Link'
import Button from '../components/Button'
import { Context } from '../components/Store'
import printUsername from '../utils/printUsername'

const AuthorizedView: React.FC = () => {
  const { user } = React.useContext(Context)

  return (
    <div className='container'>
      <p>
        Your Telegram handle and Metamask wallet address are now registered.
        <br />
        Username: {printUsername(user || {})} <br />
        Wallet address: {user?.address}
      </p>
      <div className={'my-20'}>
        <Link to={'/groups'}>
          <Button green>View Dashboard</Button>
        </Link>
      </div>
      {/* <div className={'my-20'}>
        <a href={'tg://'}>
          <Button pink>Back to Telegram</Button>
        </a>
      </div> */}
    </div>
  )
}

export default AuthorizedView
