import {isAndroid, isIOS} from 'react-device-detect'
import Button from '../../components/Button'
import {ReactComponent as MetamaskIcon} from '../../styles/img/metamask.svg'

declare let window: Window

const onOpenMetaMaskButtonClick = () => {
  window.location.replace('metamask://;')
}

export default function renderOpenMetamaskNotification() {
  const isWebInterface = window.Telegram.WebApp.initData.length > 0;

  if (isWebInterface) {
    return "Please, open metamask app";
  }

  if (isIOS || isAndroid) {
    return (
      <Button green onClick={() => onOpenMetaMaskButtonClick()}>
        Open your Metamask <MetamaskIcon className="MetamaskIcon ms-12" />
      </Button>
    )
  }

  return 'Metamask extension will open now, please wait'
}
