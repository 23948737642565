import toast from 'react-hot-toast'

import { isMobile } from 'react-device-detect'
import { User } from './Types/models/User'
import { get } from '../../pages/ConnectMetamask/helpers/api'

declare let window: any

const getAuthStringFromWebApp = () => window.Telegram.WebApp.initData

const isWebApp = () => !!getAuthStringFromWebApp()

const getWebAppAuthObject = () =>
  isWebApp() &&
  JSON.parse(
    Object.fromEntries(new URLSearchParams(decodeURIComponent(getAuthStringFromWebApp())))
      .user
  )

const loginURLkey = 'Telegram.LoginURL.initData'
const IS_AUTHORIZED_KEY = 'isAuthorized'

const getAuthStringFromBrowser = () => {
  const authString = window.localStorage.getItem(loginURLkey)

  return !authString ? undefined : authString
}

const getBrowserAuthObject = (): { [key: string]: any } | undefined =>
  getAuthStringFromBrowser() ?
    Object.fromEntries(new URLSearchParams(getAuthStringFromBrowser()))
    :
    undefined

const hideAuthStringFromLoginURL = () => {
  const userFromSearchParams = new URLSearchParams(window.location.search)

  if (!userFromSearchParams.has('id')) {
    return
  }

  const groupId = userFromSearchParams.get('groupId')

  if (groupId) {
    window.localStorage.setItem('groupId', `${groupId}`)
    userFromSearchParams.delete('groupId')
  }

  window.localStorage.setItem(loginURLkey, userFromSearchParams.toString())

  // DO NOT REMOVE PARAMS FROM URL, cuz that breaks the flow "in-app browser -> native browser"
}

const getAuthObject = () => getWebAppAuthObject() || getBrowserAuthObject()

export async function getAddressFromMetamaskExtension(): Promise<string> {
  if (isWebApp() || !window.ethereum) return ''

  try {
    const accounts = await window.ethereum?.request({ method: 'eth_requestAccounts' })
    return accounts?.[0] || ''
  } catch (error: any) {
    if (error?.code === -32002) {
      toast.error('Please, open your Metamask extension')
    }
    throw error
  }
}

const getUser = async () => {
  hideAuthStringFromLoginURL()
  
  const authObject = getAuthObject()

  if (!authObject) return null
  try {
    const { data: user } = await get<User>(`/users/${authObject.id}`)

    if (!user) return null

    return user
  } catch (error: any) {
    toast.error(error?.response?.data?.message || error?.message)
    return null
  }
}

const getAuthHeader = () => ({
  headers: {
    telegramData: encodeURIComponent(getAuthStringFromWebApp() || getAuthStringFromBrowser())
  }
})

async function getWalletConnectConnection(userId: any) {
  if (!userId) return []

  const { data } = await get<any>(`/walletConnectSessions/?telegramUserId=${userId}`)
  return data.accounts
}

function compareAddresses(addr1 = '', addr2 = '') {
  return addr1?.toLowerCase() !== addr2?.toLowerCase()
}

export async function checkAuth() {
  const user = await getUser()
  const params = getAuthObject()

  if (!user) {
    throw new Error('Not authorised')
  }

  // DESKTOP + MM extension
  if (!isMobile && window.ethereum) {
    let address

    const isAuth = window.localStorage.getItem(IS_AUTHORIZED_KEY)

    if (!isAuth || isAuth === 'false') {
      throw new Error('The account is disconnected')
    }

    try {
      ;[address] = await getWalletConnectConnection(params?.id)
    } catch (error) {
      address = window.ethereum?._state?.accounts?.[0]
    }

    if (!address || compareAddresses(user.address, address)) {
      throw new Error('Not authorised')
    }

    console.log('[auth] is Authorized with addr', address)
    return address
  }

  // MOBILE
  // or
  // DESKTOP in telegram (e.g. private msg with bot)
  if (isMobile || isWebApp()) {
    if (!params?.id) {
      throw new Error('user ID is not provided')
    }

    const accounts = await getWalletConnectConnection(params?.id)

    if (!accounts) {
      throw new Error('Not authorised')
    }

    return accounts[0]
  }

  throw new Error('Not authorised')
}


export {
  getAuthStringFromWebApp,
  getWebAppAuthObject,
  loginURLkey,
  IS_AUTHORIZED_KEY,
  getAuthStringFromBrowser,
  getBrowserAuthObject,
  getAuthObject,
  getUser,
  getAuthHeader,
  isWebApp
}
