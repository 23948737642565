import React from 'react'

import isAssetsEqual from '../utils/isAssetsEqual'
import Button from './Button'
import Input from './Input'
import { Asset } from './Store/Types/models/Asset'

export type TokenSelectorProps = {
  assets: Asset[]
  maxAssetsShown?: number
  token: Asset
  setToken: (newAsset: Asset) => void
  amount: number
  setAmount: (newAmount: number) => void
  label?: string
  className?: string
}

const TokenSelector: React.FC<TokenSelectorProps> = ({
  assets,
  maxAssetsShown = 10,
  token,
  setToken,
  amount,
  setAmount,
  label,
  className
}) => {
  const [search, setSearch] = React.useState('')
  const isSelected = (asset: Asset) => isAssetsEqual(asset, token)
  const amountRef = React.useRef<HTMLInputElement>(null)
  const [amountFocused, setAmountFocused] = React.useState(false)

  if (amountRef?.current) {
    amountRef.current.addEventListener('blur', () => setAmountFocused(false))
    amountRef.current.addEventListener('focus', () => setAmountFocused(true))
  }

  return (
    <div className={`TokenSelector ${className}`}>
      {label && <div className="TokenSelector__label">{label}</div>}
      <div
        className={`
        TokenSelector__frame
        ${amountFocused && 'focused-input'}
        ${amount > 0 && amount <= parseFloat(token.balanceInEthers) && 'accepted-input'}
      `}
      >
        <Input
          _ref={amountRef}
          number
          value={amount}
          onChange={setAmount}
          min={0}
          max={token.balanceInEthers}
          placeholder="0.05"
          className="TokenSelector__Amount"
        />
        <div className="d-flex flex-row flex-wrap">
          {assets.length >= maxAssetsShown && (
            <Input
              value={search}
              onChange={setSearch}
              placeholder="Search..."
              className="TokenSelector__Search"
            />
          )}
          {assets
            .filter((asset) =>
              search.length === 0
                ? true
                : asset.tokenSymbol
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase())
            )
            .slice(0, maxAssetsShown)
            .map((asset) => (
              <Button
                green
                key={asset.tokenSymbol}
                className="TokenSelector__item"
                style={isSelected(asset) ? 'green' : 'transparent'}
                onClick={() => setToken(asset)}
              >
                {asset.tokenSymbol}
              </Button>
            ))}
        </div>
      </div>
    </div>
  )
}

export default TokenSelector
