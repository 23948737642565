import { isIOS } from 'react-device-detect'
import WalletConnect from '@walletconnect/client'
import { walletConnectParamsAndroid, walletConnectParamsIOS } from './walletConnectParams'

declare let window: any

function WalletConnectWallet() {
  let connector = new WalletConnect(
    isIOS ? walletConnectParamsIOS : walletConnectParamsAndroid
  )

  async function disconnect() {
    if (connector.connected) {
      await connector.killSession()
    }
    connector.off('connect')
    window.localStorage.removeItem('walletconnect')
  }

  async function connect(callback: any): Promise<void> {
    await disconnect()

    connector = new WalletConnect(
      isIOS ? walletConnectParamsIOS : walletConnectParamsAndroid
    )

    connector.on('connect', () => {
      try {
        callback(connector.session)
      } catch (error) {
        connector.off('connect')
      }
    })

    if (!connector.connected) {
      return connector.createSession()
    }
  }

  function sendTransaction(transaction: any) {
    return connector.sendTransaction(transaction)
  }

  function signMessage(args: any[]) {
    return connector.signMessage(args)
  }

  function setExistingSession(existingSession: any) {
    if (!existingSession) {
      return
    }

    connector.session = existingSession
  }

  return {
    type: 'WalletConnect',
    connect,
    disconnect,
    sendTransaction,
    signMessage,
    setExistingSession
  }
}

export default WalletConnectWallet()
