import React, { useEffect, useState } from 'react'

import axios from 'axios'

import { getAuthHeader } from '../components/Store/auth'

const generateURL = (path: string, params?: object): string =>
  process.env.REACT_APP_SERVER_URL +
  path +
  (params && Object.keys(params).length > 0
    ? `?${new URLSearchParams(params as URLSearchParams).toString()}`
    : '')

type VoidCallback = () => void
const useGet = (
  path: string,
  params?: object | URLSearchParams,
  extraConf?: { getRefetcher: (cb: VoidCallback) => void }
) => {
  const [res, setRes] = React.useState<any>(null)
  const [counter, setCounter] = React.useState(0)
  React.useEffect(() => {
    setRes(null)
    ;(async () => {
      setRes((await axios.get(generateURL(path, params), getAuthHeader())).data)
    })()
  }, [counter])

  extraConf?.getRefetcher(() => setCounter((n) => n + 1))

  return res
}

const useQuery = <T>(path: string, params?: any): {
  data: T,
  error: any
  loading: boolean,
  refetch: () => Promise<T>
} => {
  let request: any = null
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<any>(null)
  const [loading, setLoading] = useState<any>(false)

  async function call(): Promise<any> {
    try {
      setLoading(true)
      request = axios.get(generateURL(path, params), getAuthHeader())
      const response = await request
      setData(response.data)
      setLoading(false)
      request = null
      return response.data
    } catch (err) {
      request = null
      setError(err)
      setLoading(false)
      throw err
    }
  }

  useEffect(() => {
    if (!request) {
      call()
    }
  }, [])

  return {
    data,
    error,
    loading,
    refetch: () => call()
  }
}

const usePost = (path: string, data: object, params?: object | URLSearchParams) => {
  const [res, setRes] = React.useState<any>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!loading) {
      setLoading(true)
      ;(async () =>
        setRes((await axios.post(generateURL(path, params), data, getAuthHeader())).data))()
    }
  }, [])

  return res
}

const usePut = (path: string, data: object, params?: object | URLSearchParams) => {
  const [res, setRes] = React.useState<any>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!loading) {
      setLoading(true)
      ;(async () =>
        setRes((await axios.put(generateURL(path, params), data, getAuthHeader())).data))()
    }
  }, [])

  return res
}

export { generateURL, useQuery, useGet, usePost, usePut }
