import React from 'react'

import { ReactComponent as UserIcon } from '../styles/img/user.svg'

type TitleProps = {
  title: string
  users?: number
  className?: string
}

const Title: React.FC<TitleProps> = ({ title, users, className }) => (
  <div className={`Title ${className}`}>
    <h2 className="Title__h2">{title}</h2>
    {users && (
      <>
        <div className="Title__number">{users}</div>
        <UserIcon />
      </>
    )}
  </div>
)

export default Title
