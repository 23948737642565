import React from 'react'

import { Asset } from './Store/Types/models/Asset'
import prettifyBalance from '../utils/prettifyBalance'
import { ETH_TOKEN_NAME, VOID_ETHER } from '../consts'
import Button from './Button'
import Link from './Link'


type DaoAssetsPropsType = {
  groupId: number
  daoAssets: Asset[]
}


const DaoAssets: React.FC<DaoAssetsPropsType> = ({ groupId, daoAssets }) => {
  const isEthersInDaoAssets = daoAssets.find(
    (asset: any) => asset.tokenSymbol === ETH_TOKEN_NAME
  )

  if (!isEthersInDaoAssets) {
    daoAssets.push(VOID_ETHER)
  }

  return (
    <div className="d-flex" style={{ gap: 5, overflow: 'auto' }}>
      {daoAssets.map(({ tokenSymbol, balanceInEthers, isZeroBalance }) => {
        const href = `/${groupId}/new-proposal/transfer-funds/${tokenSymbol}`
        return (
          <Link
            className="d-flex flex-shrink-0"
            key={`transfer-funds-action-for${tokenSymbol}`}
            to={href}
          >
            <Button green disabled={isZeroBalance}>
              {prettifyBalance(balanceInEthers)} {tokenSymbol}
            </Button>
          </Link>
        )
      })}
    </div>
  )
}


export default DaoAssets
