import { useContext, useState } from 'react'

import { Context } from '../components/Store'
import { get, post, put } from '../pages/ConnectMetamask/helpers/api'
import { Proposal, PROPOSAL_TYPES } from '../components/Store/Types/models/Proposal'
import renderOpenMetamaskNotification from '../pages/common/RenderOpenMetamaskNotification'

function useProposals() {
  const [loading, setLoading] = useState(false)
  const { openPopup, closePopup, updatePopup, user, wallet } = useContext(Context)

  async function execute(proposalId: number): Promise<any> {
    try {
      setLoading(true)

      const { data: transaction } = await get(
        `/proposals/${proposalId}/generateExecuteTransaction`
      )

      openPopup({
        text: <p style={{ textAlign: 'center' }}>{renderOpenMetamaskNotification()}</p>,
        button: {
          label: 'Back',
          color: 'pink',
          onClick: () => closePopup()
        }
      })

      const txHash = await wallet.sendTransaction(transaction)

      updatePopup({
        showLoader: true
      })

      const result = await put(`/proposals/execute`, { proposalId, txHash })

      setLoading(false)
      closePopup()

      return result
    } catch (error: any) {
      closePopup()
      setLoading(false)
      throw error
    }
  }

  async function vote(proposalId: number): Promise<any> {
    try {
      const { data: proposal }: { data: Proposal } = await get(`/proposals/${proposalId}`)
      setLoading(true)

      openPopup({
        text: <p style={{ textAlign: 'center' }}>{renderOpenMetamaskNotification()}</p>,
        button: {
          label: 'Back',
          color: 'pink',
          onClick: () => closePopup()
        }
      })

      const signature = await wallet.signMessage([user?.address, proposal?.safeTxHash])

      updatePopup({
        showLoader: true
      })

      const result = await put(`/proposals/vote`, { proposalId, signature })

      setLoading(false)
      closePopup()

      return result
    } catch (error: any) {
      closePopup()
      setLoading(false)
      throw error
    }
  }

  async function reject(proposalId: number): Promise<any> {
    try {
      setLoading(true)
      const { data: proposal }: { data: any } = await get(`/proposals/${proposalId}`)

      const newRejectProposal = {
        type: PROPOSAL_TYPES.REJECT_PROPOSAL,
        groupId: parseInt(`${proposal.group.id}`, 10),
        rejectForNonce: proposal.nonce
      }

      openPopup({
        text: <p style={{ textAlign: 'center' }}>{renderOpenMetamaskNotification()}</p>,
        button: {
          label: 'Back',
          color: 'pink',
          onClick: () => closePopup()
        }
      })

      const resp: any = await post(`/proposals/buildProposalPayload`, newRejectProposal)
      const { payload, contractTransactionHash: hash } = resp.data

      const signature = await wallet.signMessage([user?.address, hash])

      updatePopup({
        showLoader: true
      })
      
      const result = await post('/proposals/create', {
        proposal: newRejectProposal,
        proposalPayload: payload,
        hash,
        signature
      })

      setLoading(false)
      closePopup()
      return result
    } catch (error: any) {
      closePopup()
      setLoading(false)
      throw error
    }
  }

  return {
    loading,
    execute,
    vote,
    reject
  }
}

export default useProposals
