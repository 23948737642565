import React from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns'

import toast from 'react-hot-toast'
import Title from '../components/Title'
import Divider from '../components/Divider'
import {
  Proposal as ProposalType,
  PROPOSAL_STATES,
  PROPOSAL_TYPES
} from '../components/Store/Types/models/Proposal'
import Member from '../components/Member'
import MembersList from '../components/MembersList'
import Button from '../components/Button'
import proposalTitle from '../utils/proposalTitle'
import { useGet } from '../components/Store'
import { LoadingScreen } from '../components/Loader'
import Link from '../components/Link'
import useProposals from '../hooks/useProposals'
import Message from '../components/Message'
import useTitle from '../hooks/useTitle'

type ProposalProps = {
  proposal: any
}

const Proposal: React.FC<ProposalProps> = ({ proposal }) => {
  const formattedCreatedDate = format(new Date(proposal.dateCreated), 'dd.MM.yyyy HH:mm')

  const { loading, execute, vote, reject } = useProposals()
  const navigate = useNavigate()

  async function handleConfirm(id: any) {
    try {
      const result = await vote(id)

      if (result && result.status === 201) {
        toast.success('Successfully executed!')
        navigate(`/proposals/${proposal.group.id}/1`, { replace: true })
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message)
    }
  }

  async function handleExecute(id: any) {
    try {
      const result = await execute(id)

      if (result && result.status === 201) {
        toast.success('Successfully executed!')
        navigate(`/proposals/${proposal.group.id}/1`, { replace: true })
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message)
    }
  }

  async function handleReject(proposalId: number) {
    try {
      const result = await reject(proposalId)

      if (result && result.status === 201) {
        toast.success('Successfully created proposal to reject!')
        navigate(`/proposals/${proposal.group.id}/1`, { replace: true })
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message)
    }
  }

  return (
    <div className="Proposal">
      <div className="container d-flex flex-column">
        <div className="Proposal__header">
          {formattedCreatedDate}
          <br />
          by {proposal.creatorName}
          <br />
          <Link to={proposal.gnosisUrl} className="font-inherit underline">
            "Gnosis Safe" link
          </Link>
          <div className={'mt-10'}>
            {proposal.status === PROPOSAL_STATES.EXECUTED && (
              <span className={'badge rounded-pill bg-dark'}>{proposal.status}</span>
            )}
          </div>
        </div>
        {[
          PROPOSAL_TYPES.ADD_PARTICIPANT,
          PROPOSAL_TYPES.REMOVE_PARTICIPANT,
          PROPOSAL_TYPES.REJECT_PROPOSAL
        ].includes(proposal.type) ? (
          <>
            <h3 className="h3">
              {proposal.type === PROPOSAL_TYPES.REJECT_PROPOSAL
                ? `${proposalTitle(proposal, true)}`
                : `${proposal.nonce}. ${proposalTitle(proposal, true)}`}
            </h3>
            <Title title={proposal.group.title} className="mb-24" />
          </>
        ) : (
          <>
            <Title
              title={`${proposal.nonce}. ${proposal.group.title}`}
              className="mb-24"
            />
            <h3 className="h3">{proposalTitle(proposal, true)}</h3>
          </>
        )}
        <div className="d-flex flex-row">
          {proposal.address && (
            <Member
              user={proposal.user || { address: proposal.address }}
              className="mt-10 mb-37 me-0 d-inline-block"
            />
          )}
        </div>
        {proposal.description && (
          <Message
            className="mb-37"
            user={proposal.creator}
            text={proposal.description}
          />
        )}
      </div>
      <Divider />
      <div className="container my-12">
        <h3 className="h3">
          New voting amount: {proposal.newThreshold || proposal.groupThreshold}
        </h3>
      </div>
      <Divider />
      <div className="container my-10">
        <div className="d-flex flex-row justify-content-between mb-15">
          <h3 className="h3">Confirmed</h3>
          <h3 className="h3">
            {proposal.confirmedBy.length}/{proposal.groupThreshold}
          </h3>
        </div>
        <MembersList users={proposal.confirmedBy} />
      </div>
      <Divider className="mb-auto" />
      <div className="container mb-80">
        {proposal.numberInQueue > 1 && (
          <div className="p p--s mb-20">
            Can be executed after {proposal.numberInQueue - 1} previous proposal
            {proposal.numberInQueue > 2 && 's'}
          </div>
        )}
        <div className="d-flex flex-row">
          {!proposal.confirmedByUser && (
            <Button
              disabled={loading}
              green
              onClick={() => handleConfirm(proposal.id)}
              className={proposal.canBeExecuted && 'me-10'}
            >
              Confirm
            </Button>
          )}
          {proposal.canBeExecuted && (
            <Button disabled={loading} green onClick={() => handleExecute(proposal.id)}>
              Execute
            </Button>
          )}
          {proposal.canBeRejected && (
            <Button
              pink
              disabled={loading}
              onClick={(event: React.MouseEvent) => {
                event.preventDefault()
                event.stopPropagation()
                return handleReject(proposal.id)
              }}
            >
              Reject
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

type IParams = {
  proposalId: string
}

export default () => {
  const { proposalId } = useParams<IParams>()
  const proposal: ProposalType | any = useGet(`/proposals/${proposalId}`)
  useTitle(proposal && proposalTitle(proposal, true))

  return !proposal ? <LoadingScreen /> : <Proposal proposal={proposal} />
}
