import React from 'react'


type TextAreaProps = {
  value: string | number
  onChange: (value: any) => void
  onBlur?: (e: any) => void
  label?: string
  placeholder?: string
  className?: string
  children?: JSX.Element | JSX.Element[]
}


const TextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  label,
  placeholder,
  className,
  children,
  onBlur,
}) => (
  <div className={`TextArea ${className}`}>
    <div className="TextArea__label">{label}</div>
    <textarea
      className='TextArea__input'
      value={value}
      onBlur={onBlur}
      autoComplete="off"
      autoCorrect="off"
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
    />
    {children && <div className="TextArea__children">{children}</div>}
  </div>
)


export default TextArea
