import React from 'react'

import Member from './Member'
import { MemberItem } from './Store/Types/models/User'
import Button from './Button'
import Link from './Link'

import { ReactComponent as Cross } from '../styles/img/cross.svg'
import { Group } from './Store/Types/models/Group'

type MembersListProps = {
  group?: Group
  users?: MemberItem[]
  editable?: boolean
  cantRemoveOwner?: boolean
  addButton?: boolean
  dropdown?: boolean
  className?: string
  onClick?: (user: MemberItem) => void
}

const NumberOfUsersInClosedDropdown = 5

const MembersList: React.FC<MembersListProps> = ({
  group,
  users: usersProp,
  editable,
  cantRemoveOwner,
  addButton,
  dropdown,
  className,
  onClick
}) => {
  const [expanded, setExpanded] = React.useState(false)
  const users = usersProp || group?.users || []

  return (
    <div className={`MembersList ${className}`}>
      {users
        .slice(
          0,
          dropdown && !expanded ? NumberOfUsersInClosedDropdown : users.length + 1
        )
        .map(user => (
          <Member
            key={user.id}
            user={user}
            editable={
              editable &&
              users.length > 1 &&
              !(cantRemoveOwner && user?.id === group?.ownerId)
            }
            simple={!editable}
            className='mb-10'
            onClick={() => editable && onClick && onClick(user)}
          />
        ))}
      {dropdown && users.length > NumberOfUsersInClosedDropdown && (
        <Button onClick={() => setExpanded(!expanded)} className="green-box">
          {expanded ? 'Hide' : 'Show all'}
        </Button>
      )}
      {editable && addButton && group && (
        <Link to={`/${group.id}/new-proposal/add-participant`}>
          <Button className="MembersList__add">
            Add
            <Cross className="MembersList__add__plus" />
          </Button>
        </Link>
      )}
    </div>
  )
}

export default MembersList
